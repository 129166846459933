import React from "react";
import Layout from "../components/Layout";
import Product from "../components/Products/Product";
import { hamProducts } from "../data/ham/hamProduct";

const Ham = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  return (
    <Layout title={hamProducts[i].recipeName} description="">
      <Product id={i} proteinProducts={hamProducts} />
    </Layout>
  );
};

export default Ham;
